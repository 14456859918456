import { connect } from 'react-redux';

import HeroBanner from './HeroBanner';

const mapStateToProps = state => ({
  image: state.campaign.heroBanner.image,
  title: state.campaign.heroBanner.title,
  ctaLabel: state.campaign.heroBanner.ctaLabel,
  bodyText: state.campaign.heroBanner.bodyText,
  footNote: state.campaign.heroBanner.footNote
});

const heroBannerContainer = connect(mapStateToProps)(HeroBanner);

export default heroBannerContainer;
