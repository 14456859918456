import { connect } from 'react-redux';

import Testimonials from './Testimonials';

const mapStateToProps = state => ({
  users: state.testimonials.users,
  cta: state.testimonials.cta,
  href: state.testimonials.href
});

const testimonialsContainer = connect(mapStateToProps)(Testimonials);

export default testimonialsContainer;
