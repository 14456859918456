import { connect } from 'react-redux';

import BecomeMemberWidget from './BecomeMemberWidget';

const mapStateToProps = state => ({
  campaignIsActive: state.campaign.campaignIsActive,
  title: state.campaign.salesWidget.title,
  subtitle: state.campaign.salesWidget.subtitle,
  ctaLabel: state.campaign.salesWidget.ctaLabel,
  campaignImage: state.campaign.salesWidget.stickerImage
});

const BecomeMemberWidgetContainer =
  connect(mapStateToProps)(BecomeMemberWidget);

export default BecomeMemberWidgetContainer;
