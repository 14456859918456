/* eslint-disable prefer-object-spread */
const config = () => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    return Object.assign({}, window.__CONFIG__);
  }
  return process.env;
};

export default config();
