import { connect } from 'react-redux';

import BuySubscriptionRedesign from './BuySubscriptionRedesign';

const mapStateToProps = (state, props) => ({
  campaignIsActive: state.campaign.campaignIsActive,
  campaignEndDate: state.campaign.campaignEndDate,
  counterShowDate: state.campaign.buySubscription.counterShowDate,
  countdownTitle: state.campaign.buySubscription.countdownTitle,
  title: state.campaign.buySubscription.title,
  subtitle: state.campaign.buySubscription.subtitle,
  footnote: state.campaign.buySubscription.footnote,
  offerBoxes: state.campaign.buySubscription.boxes,
  isRedesign: props.isRedesign || state.campaign.forceRedesign,
  product: state.config.product
});

const BuySubscriptionRedesignContainer = connect(mapStateToProps)(
  BuySubscriptionRedesign
);

export default BuySubscriptionRedesignContainer;
