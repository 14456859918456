/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import localize from '@src/i18n';
import ButtonSecondary from '@comp/Buttons/ButtonSecondary';
import AdDisclaimer from '@comp/Sales/AdDisclaimer';
import s from './BecomeMemberWidget.scss';

const BecomeMemberWidget = ({
  title,
  forcedTitle,
  subtitle,
  ctaLabel,
  ctaClickHandler,
  campaignImage,
  footNote,
  showAdDiscalimer,
  campaignIsActive,
  isVertical,
  isGrey
}) => (
  <div className={s.container}>
    <div className={s.boxWrapper} onClick={ctaClickHandler} role="button">
      <div
        className={cx({
          [s.box]: true,
          [s.grey]: isGrey
        })}
      >
        {showAdDiscalimer && <AdDisclaimer />}
        {campaignIsActive && campaignImage && (
          <img
            src={campaignImage}
            alt={localize('campaign')}
            className={s.campaignImage}
          />
        )}
        <div
          className={cx({
            [s.content]: true,
            [s.isCampaign]: campaignIsActive && campaignImage,
            [s.vertical]: isVertical
          })}
        >
          <div className={s.widgetInner}>
            <h2
              className={cx({
                [s.header]: true,
                [s.smallFont]: title.length > 25
              })}
            >
              {forcedTitle || title}
            </h2>
            {subtitle && (
              <div
                className={s.body}
                dangerouslySetInnerHTML={{
                  __html: subtitle
                }}
              />
            )}
          </div>
          <ButtonSecondary
            expand
            size={{
              smallBreakPoint: 'small',
              mediumBreakPoint: 'medium',
              largeBreakPoint: 'large'
            }}
            className={s.cta}
            text={ctaLabel}
          />

          {footNote && (
            <div
              className={s.footNote}
              dangerouslySetInnerHTML={{ __html: footNote }}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

BecomeMemberWidget.propTypes = {
  title: PropTypes.string,
  forcedTitle: PropTypes.string,
  subtitle: PropTypes.string,
  ctaLabel: PropTypes.string,
  footNote: PropTypes.string,
  ctaClickHandler: PropTypes.func,
  showAdDiscalimer: PropTypes.bool,
  campaignIsActive: PropTypes.bool,
  isVertical: PropTypes.bool,
  isGrey: PropTypes.bool,
  campaignImage: PropTypes.string
};

export default BecomeMemberWidget;
