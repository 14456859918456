/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import classNames from 'classnames/bind';

import {
  SMALL_MEDIA,
  MEDIUM_MEDIA,
  LARGE_MEDIA
} from '@src/utils/constants/breakpoints';
import ButtonHero from '@comp/Buttons/ButtonHero';
import AdDisclaimer from '@comp/Sales/AdDisclaimer';
import s from './HeroBanner.scss';

const cx = classNames.bind(s);

const HeroBanner = ({
  image,
  title,
  bodyText,
  ctaLabel,
  ctaClickHandler,
  footNote,
  showAdDiscalimer,
  isHigher,
  isRedesign
}) => (
  <div
    className={cx({
      container: true,
      isHigher,
      isHigherRedesign: isRedesign
    })}
  >
    <div className={s.imageWrapper}>
      {image && (
        <div
          className={cx(s.image, 'lazyload')}
          data-bgset={`
        ${image.desktop} 3000w [${LARGE_MEDIA}] |
        ${image.tablet} 990w [${MEDIUM_MEDIA}] |
        ${image.mobile} 768w [${SMALL_MEDIA}]`}
        />
      )}
    </div>

    <div className={s.boxWrapper}>
      <div className={s.box}>
        {showAdDiscalimer && <AdDisclaimer />}

        <div className={s.content}>
          <h1 className={s.header}>{title}</h1>

          {bodyText && (
            <div className={s.body}>
              <p dangerouslySetInnerHTML={{ __html: bodyText }} />
            </div>
          )}

          <ButtonHero
            expand
            size={{
              smallBreakPoint: 'small',
              mediumBreakPoint: 'medium',
              largeBreakPoint: 'large'
            }}
            className={cx({ cta: true, ctaRedesign: isRedesign })}
            text={ctaLabel}
            onClick={ctaClickHandler}
          />

          {footNote && (
            <div
              className={s.footNote}
              dangerouslySetInnerHTML={{ __html: footNote }}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

HeroBanner.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  ctaLabel: PropTypes.string,
  bodyText: PropTypes.string,
  footNote: PropTypes.string,
  ctaClickHandler: PropTypes.func,
  showAdDiscalimer: PropTypes.bool,
  isRedesign: PropTypes.bool,
  isHigher: PropTypes.bool
};

export default HeroBanner;
